html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'Creepster', cursive;
  src: local("Creepster-Regular"), url(Creepster-Regular.ttf) format("ttf");
}

@font-face {
  font-family: 'Road Rage', cursive;
  src: local("RoadRage"), url(RoadRage-Regular.ttf) format("ttf");
}

body {
  margin: 0;
  font-family: 'Open Sans', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: 'Creepster', cursive;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.pad {   
  display:inline-block; position:relative; z-index:1; width:300px; height:300px; margin:20px; padding:25px;
  border-radius:20px;
  background:linear-gradient(0.06deg, rgba(250, 250, 250, 1) 0%, 
                                      rgba(246, 246, 246, 1) 29.62%, 
                                      rgba(234, 234, 234, 1) 58.47%, 
                                      rgba(215, 215, 215, 1) 86.92%, 
                                      rgba(204, 204, 204, 1) 99.22%); 
  box-shadow:-4px 8px 8px rgba(0,0,0,0.1), -8px 16px 16px rgba(0,0,0,0.1), -16px 32px 32px rgba(0,0,0,0.15), -32px 64px 64px rgba(0,0,0,0.25);  
}
.pad:before {
  content:''; position:absolute; z-index:-1; top:0; left:0; right:0; bottom:0; margin:0;
}

.pad.z { 
  border-radius:0 100px; 
  background:linear-gradient(0.01000000000001deg, rgba(179, 179, 179, 1) 8.92%, 
                                                  rgba(213, 213, 213, 1) 31.91%, 
                                                  rgba(240, 240, 240, 1) 54.33%, 
                                                  rgba(250, 250, 250, 1) 67.52%); 
  
}
.pad.z:before {   
  margin:8px;
  border-radius:0 96px;
  background:linear-gradient(0.06deg, rgba(250, 250, 250, 1) 0%, 
                                      rgba(246, 246, 246, 1) 29.62%, 
                                      rgba(234, 234, 234, 1) 58.47%, 
                                      rgba(215, 215, 215, 1) 86.92%, 
                                      rgba(204, 204, 204, 1) 99.22%); 
}

